import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Callout = makeShortcode("Callout");
const Accordion = makeShortcode("Accordion");
const Aligner = makeShortcode("Aligner");
const Image = makeShortcode("Image");
const Authors = makeShortcode("Authors");
const Box = makeShortcode("Box");
const Button = makeShortcode("Button");
const Checklist = makeShortcode("Checklist");
const Chocolate = makeShortcode("Chocolate");
const Icon = makeShortcode("Icon");
const Code = makeShortcode("Code");
const Column = makeShortcode("Column");
const Flash = makeShortcode("Flash");
const Card = makeShortcode("Card");
const InfoBlock = makeShortcode("InfoBlock");
const List = makeShortcode("List");
const Link = makeShortcode("Link");
const Process = makeShortcode("Process");
const StatusBanner = makeShortcode("StatusBanner");
const Tout = makeShortcode("Tout");
const Video = makeShortcode("Video");
const Text = makeShortcode("Text");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "component-palette",
      "style": {
        "position": "relative"
      }
    }}>{`Component Palette`}<a parentName="h1" {...{
        "href": "#component-palette",
        "aria-label": "component palette permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`This page serves as a palette for all the components this project has to offer.`}</p>
    <hr></hr>
    <h4 {...{
      "id": "definitions",
      "style": {
        "position": "relative"
      }
    }}>{`Definitions`}<a parentName="h4" {...{
        "href": "#definitions",
        "aria-label": "definitions permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`WYSIWYG`}</strong>{`: What you see is what you get.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Component`}</strong>{`: A lego block like code element you can use for more advanced content like buttons.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`MD`}</strong>{`: Shorthand for Markdown`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`MDX`}</strong>{`: Shorthand for MarkdownExtended (i.e. MDX is the name of the rendering engine we use.)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Child/Children`}</strong>{`: Content that is wrapped by a Component. New lines between content will be recognized as a new child.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Child Rule`}</strong>{`: A pattern introduced for more complex components that recognized each individual child as a repeatable element of a component. (i.e. List, InfoBlock, etc.)`}</p>
      </li>
    </ul>
    <hr></hr>
    <h4 {...{
      "id": "disclaimers",
      "style": {
        "position": "relative"
      }
    }}>{`Disclaimers`}<a parentName="h4" {...{
        "href": "#disclaimers",
        "aria-label": "disclaimers permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`To see dark/light mode variants click the moon/sun in the header`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`This palette is primarily to showcase component usage in a vacuum.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`For an in-depth chart on props available for these components please see the `}<a parentName="p" {...{
            "href": "/fork-me/guides/cheatsheet"
          }}>{`Cheatsheet`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Any component that's not listed here, but exists in the project`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Always remember. What you see is what you get (WYSIWYG).`}</p>
      </li>
    </ul>
    <hr></hr>
    <Callout variant="warning" icon="warning" mdxType="Callout">
      <h4 {...{
        "id": "child-rule-in-effect",
        "style": {
          "position": "relative"
        }
      }}>{`Child Rule in Effect`}<a parentName="h4" {...{
          "href": "#child-rule-in-effect",
          "aria-label": "child rule in effect permalink",
          "className": "anchor-link after"
        }}><span parentName="a">{`¶`}</span></a></h4>
      <p>{`This means that each new line in your Markdown file is treated as its own "child", which is really important in terms of what gets rendered where.`}</p>
    </Callout>
    <h2 {...{
      "id": "accordion",
      "style": {
        "position": "relative"
      }
    }}>{`Accordion`}<a parentName="h2" {...{
        "href": "#accordion",
        "aria-label": "accordion permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Click the Accordion header to show and hide the content it offers.`}</p>
    <p>{`The first element is always the header. Every other element will be hidden away.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`
<Accordion>
    
Child 1 

Child 2

</Accordion>
`}</code></pre>
    <hr></hr>
    <Accordion mdxType="Accordion">
      <p>{`Child 1 `}</p>
      <p>{`Child 2`}</p>
    </Accordion>
    <hr></hr>
    <h2 {...{
      "id": "aligner",
      "style": {
        "position": "relative"
      }
    }}>{`Aligner`}<a parentName="h2" {...{
        "href": "#aligner",
        "aria-label": "aligner permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`This is a utility component built to work with the `}<inlineCode parentName="p">{`Image`}</inlineCode>{` component.`}</p>
    <blockquote>
      <p parentName="blockquote">{`This component exists because remark-images wraps our elements automatically with styles that make it difficult to align an image. It only really works well if the container of the image is large enough to facilitate it.**`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`<Aligner center sx={{height: '400px', width: '100%'}}>

<Image src="/images/starfox.png" />

</Aligner>

<Aligner bottom right sx={{height: '400px', width: '100%'}}>

![starfox](/images/starfox.png)

</Aligner>
`}</code></pre>
    <hr></hr>
    <Aligner center sx={{
      height: '400px',
      width: '100%'
    }} mdxType="Aligner">
      <Image src='/images/starfox.png' mdxType="Image" />
    </Aligner>
    <Aligner bottom right sx={{
      height: '400px',
      width: '100%'
    }} mdxType="Aligner">
      <p><img parentName="p" {...{
          "src": "/images/starfox.png",
          "alt": "starfox"
        }}></img></p>
    </Aligner>
    <hr></hr>
    <h2 {...{
      "id": "authors",
      "style": {
        "position": "relative"
      }
    }}>{`Authors`}<a parentName="h2" {...{
        "href": "#authors",
        "aria-label": "authors permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`This is a utility component originally built to display the AllContributors grid.
Yet, it also serves as good way for folks to set an author at the end of a page.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`
<Authors all/>

<Authors list author="@MaximumCrash"/>
`}</code></pre>
    <hr></hr>
    <Authors all mdxType="Authors" />
    <Authors list author='@MaximumCrash' mdxType="Authors" />
    <hr></hr>
    <p>{`It's also possible to render a custom author by filling out the correct information like so:`}</p>
    <blockquote>
      <p parentName="blockquote">{`You MUST fill out the information EXACTLY how it's written below to get a custom author to render with the correct information.`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`
<Authors list>
    <Box>
        ![Réjon](/images/starfox.png) [Réjon
        Taylor-Foster](https://rejontaylor.com) [Another
        Test](https://github.com) [Twitter](https://twitter.com)
        [Medium](https://medium.com) # Réjon Taylor-Foster ## Lorem ipsum dolor
        sit amet, consectetur adipiscing elit. Donec pharetra quam augue. Nulla
        facilisi. Ut sed volutpat nisl. Cras eget nunc sed erat malesuada
        faucibus. Donec non vehicula elit. Interdum et malesuada fames ac ante
        ipsum primis in faucibus. Sed blandit imperdiet maximus. In hac
        habitasse platea dictumst. Sed id luctus justo. Morbi in tortor et quam
        lacinia finibus ac in quam. Suspendisse mollis efficitur dui eu laoreet.
        ### MaximumCrash - ideas - audio - doc - 👀 - 💦
    </Box>
</Authors>
`}</code></pre>
    <Authors list mdxType="Authors">
      <Box mdxType="Box">
        <p><img parentName="p" {...{
            "src": "/images/starfox.png",
            "alt": "Réjon"
          }}></img></p>
        <p><a parentName="p" {...{
            "href": "https://rejontaylor.com"
          }}>{`Réjon Taylor-Foster`}</a></p>
        <p><a parentName="p" {...{
            "href": "https://github.com"
          }}>{`Another Test`}</a></p>
        <p><a parentName="p" {...{
            "href": "https://twitter.com"
          }}>{`Twitter`}</a></p>
        <p><a parentName="p" {...{
            "href": "https://medium.com"
          }}>{`Medium`}</a></p>
        <p>{`Réjon Taylor-Foster`}</p>
        <p>{`Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec pharetra quam augue. Nulla facilisi. Ut sed volutpat nisl. Cras eget nunc sed erat malesuada faucibus. Donec non vehicula elit. Interdum et malesuada fames ac ante ipsum primis in faucibus. Sed blandit imperdiet maximus. In hac habitasse platea dictumst. Sed id luctus justo. Morbi in tortor et quam lacinia finibus ac in quam. Suspendisse mollis efficitur dui eu laoreet.`}</p>
        <p>{`MaximumCrash`}</p>
        <ul>
          <li parentName="ul">{`ideas`}</li>
          <li parentName="ul">{`audio`}</li>
          <li parentName="ul">{`doc`}</li>
          <li parentName="ul">{`👀`}</li>
          <li parentName="ul">{`💦`}</li>
        </ul>
      </Box>
    </Authors>
    <hr></hr>
    <h2 {...{
      "id": "button",
      "style": {
        "position": "relative"
      }
    }}>{`Button`}<a parentName="h2" {...{
        "href": "#button",
        "aria-label": "button permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`It's a button. Not much more to it.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Because this site doesn't provide any stateful functionality (besides light/dark modes), Buttons are built to serve as a unique visual display of links.`}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`
<Button to="/"> ET phone Home </Button>

<Button secondary inline to="/resources/recipes/palette"> Inlined Secondary </Button>

<Button outline to="/resources/recipes/palette"> Click Here </Button>

<Button small to="/resources/recipes/palette"> Small Button </Button>

<Button to="https://rejontaylor.com"> External Link Button </Button>
`}</code></pre>
    <hr></hr>
    <Button to='/' mdxType="Button"> ET phone Home </Button>
    <Button disabled inline to='/resources/recipes/palette' mdxType="Button">
    Disabled Inline
    </Button>
    <Button secondary inline to='/resources/recipes/palette' mdxType="Button">
    Inlined Secondary
    </Button>
    <Button outline to='/resources/recipes/palette' mdxType="Button">
    Click Here
    </Button>
    <Button small to='/resources/recipes/palette' mdxType="Button">
    Small Button
    </Button>
    <Button to='https://makerdao.com' mdxType="Button"> External Link Button </Button>
    <Button text to='/' mdxType="Button">
    Text Button
    </Button>
    <Button text disabled to='/' mdxType="Button">
    Disabled Text Button
    </Button>
    <hr></hr>
    <h2 {...{
      "id": "callout",
      "style": {
        "position": "relative"
      }
    }}>{`Callout`}<a parentName="h2" {...{
        "href": "#callout",
        "aria-label": "callout permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`This component is for callouts. It can also be written as a blockquote `}<inlineCode parentName="p">{`>`}</inlineCode>{` markdown style.`}</p>
    <p>{`To have icons show up you have to provide an icon name from our icon palette. See the `}<a parentName="p" {...{
        "href": "/guiding/writing/recipes/icons"
      }}>{`Icon Palette`}</a>{` for those.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`
<Callout>

Howdy

</Callout>

<Callout icon="lightbulb">

Kernel callout

</Callout>

> blockquote Callout Markdown Style
`}</code></pre>
    <hr></hr>
    <Callout mdxType="Callout">
      <p>{`Howdy`}</p>
    </Callout>
    <Callout icon="lightbulb" mdxType="Callout">
      <p>{`Kernel callout`}</p>
    </Callout>
    <blockquote>
      <p parentName="blockquote">{`blockquote Callout Markdown Style`}</p>
    </blockquote>
    <hr></hr>
    <h2 {...{
      "id": "checklist",
      "style": {
        "position": "relative"
      }
    }}>{`Checklist`}<a parentName="h2" {...{
        "href": "#checklist",
        "aria-label": "checklist permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`This is a non-functioning checklist component. It is simply styled to look like one.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`
<Checklist>
    Child A Child B
    <Box>
        Child C<Checklist>Child C - A Child C - B</Checklist>
    </Box>
</Checklist>
`}</code></pre>
    <hr></hr>
    <Checklist mdxType="Checklist">
      <p>{`Child A`}</p>
      <p>{`Child B`}</p>
      <Box mdxType="Box">
        <p>{`Child C`}</p>
        <Checklist mdxType="Checklist">
          <p>{`Child C - A`}</p>
          <p>{`Child C - B`}</p>
        </Checklist>
      </Box>
    </Checklist>
    <hr></hr>
    <h2 {...{
      "id": "chocolate-box",
      "style": {
        "position": "relative"
      }
    }}>{`Chocolate (Box)`}<a parentName="h2" {...{
        "href": "#chocolate-box",
        "aria-label": "chocolate box permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Originally named "Chocolate Box" components, this component takes into consideration the child rule.
Each child is recognized as a new element. Thus it's recommended for more diverse content to be contained
by a `}<inlineCode parentName="p">{`Box`}</inlineCode>{` component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`
<Chocolate>
    <Box>
        <Icon size={"40px"} name='maker' />
        Child A Some content about something
    </Box>

    <Box>
        <Icon size={"40px"} name='globe' />
        Child B Some content about something
    </Box>

    <Box>
        <Icon size={"40px"} name='wrench' />
        Child C Some content about something
    </Box>
</Chocolate>
`}</code></pre>
    <hr></hr>
    <Chocolate mdxType="Chocolate">
      <Box mdxType="Box">
        <Icon size={"40px"} name='maker' mdxType="Icon" />
        <p>{`Child A`}</p>
        <p>{`Some content about something`}</p>
      </Box>
      <Box mdxType="Box">
        <Icon size={"40px"} name='globe' mdxType="Icon" />
        <p>{`Child B`}</p>
        <p>{`Some content about something`}</p>
      </Box>
      <Box mdxType="Box">
        <Icon size={"40px"} name='wrench' mdxType="Icon" />
        <p>{`Child C`}</p>
        <p>{`Some content about something`}</p>
      </Box>
    </Chocolate>
    <hr></hr>
    <h3 {...{
      "id": "code",
      "style": {
        "position": "relative"
      }
    }}>{`Code`}<a parentName="h3" {...{
        "href": "#code",
        "aria-label": "code permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`There are 2 types of code elements.`}</p>
    <ol>
      <li parentName="ol">{`The code blocks you've been seeing so far that leverage 3 back ticks `}<inlineCode parentName="li">{`(\`\`\`)`}</inlineCode></li>
      <li parentName="ol">{`Inline code that leverage 1 back tick `}<inlineCode parentName="li">{`like this`}</inlineCode></li>
    </ol>
    <p>{`#2 makes use of the `}<inlineCode parentName="p">{`Code`}</inlineCode>{` component like so:`}</p>
    <pre><code parentName="pre" {...{}}>{`<Code>

const var = "variable";

</Code>
`}</code></pre>
    <Code mdxType="Code">const var = "variable";</Code>
    <p>{`However `}<strong parentName="p">{`inline code doesn't support language highlighting`}</strong>{`.`}</p>
    <p>{`#1 makes use of the PrismJS library in conjunction with ThemeUI to render code blocks like so.`}</p>
    <blockquote>
      <p parentName="blockquote">{`We only support so many languages (JS, Text, HTML, CSS, and Markdown). If you want more, check out the `}<a parentName="p" {...{
          "href": "https://prismjs.com/#supported-languages"
        }}>{`PrismJS documentation`}</a>{` about it.`}</p>
    </blockquote>
    <p>{`You can provide a language to show it's tab.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const variable = "string";
`}</code></pre>
    <p>{`If you want to show a file name just provide `}<inlineCode parentName="p">{`title=file-name.js`}</inlineCode>{` (or whatever the name is)`}</p>
    <div {...{
      "className": "gatsby-code-title prism-code-title"
    }}>{`palette.mdx`}</div>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`const example = "This is an example with a title tab";
`}</code></pre>
    <hr></hr>
    <h3 {...{
      "id": "column",
      "style": {
        "position": "relative"
      }
    }}>{`Column`}<a parentName="h3" {...{
        "href": "#column",
        "aria-label": "column permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`This component follows a similar pattern to that of the `}<inlineCode parentName="p">{`Chocolate Box`}</inlineCode>{` component.
To get a header though, there needs to be a child that has children. It is recommended
to use a `}<inlineCode parentName="p">{`Box`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`
<Column>

Child A - Full Width

</Column>

<Column>

Child A - with Sibling

Child B - with Sibling

</Column>

<Column>

<Box>

#### Child A

Full Width with a Header

</Box>

</Column>


<Column>

<Box>

#### Child A

With a sibling and a Header

</Box>

<Box>

#### Child B

With a sibling and a Header

</Box>

</Column>
`}</code></pre>
    <hr></hr>
    <Column mdxType="Column">
      <p>{`Child A - Full Width`}</p>
    </Column>
    <hr></hr>
    <Column mdxType="Column">
      <p>{`Child A - with Sibling`}</p>
      <p>{`Child B - with Sibling`}</p>
    </Column>
    <hr></hr>
    <Column mdxType="Column">
      <Box mdxType="Box">
        <h4 {...{
          "id": "child-a",
          "style": {
            "position": "relative"
          }
        }}>{`Child A`}<a parentName="h4" {...{
            "href": "#child-a",
            "aria-label": "child a permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`Full Width with a Header`}</p>
      </Box>
    </Column>
    <hr></hr>
    <Column mdxType="Column">
      <Box mdxType="Box">
        <h4 {...{
          "id": "child-a-1",
          "style": {
            "position": "relative"
          }
        }}>{`Child A`}<a parentName="h4" {...{
            "href": "#child-a-1",
            "aria-label": "child a 1 permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`With a sibling and a Header`}</p>
      </Box>
      <Box mdxType="Box">
        <h4 {...{
          "id": "child-b",
          "style": {
            "position": "relative"
          }
        }}>{`Child B`}<a parentName="h4" {...{
            "href": "#child-b",
            "aria-label": "child b permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h4>
        <p>{`With a sibling and a Header`}</p>
      </Box>
    </Column>
    <hr></hr>
    <h2 {...{
      "id": "flash-cards",
      "style": {
        "position": "relative"
      }
    }}>{`Flash Cards`}<a parentName="h2" {...{
        "href": "#flash-cards",
        "aria-label": "flash cards permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`These series of components (Flash) and (Card) are the (Hammer) and (Nail) equivalents
that come together to establish a Flashcard game session. `}</p>
    <p>{`To establish a session you simply place `}<inlineCode parentName="p">{`Flash`}</inlineCode>{` component with a `}<inlineCode parentName="p">{`Card`}</inlineCode>{` component inside of it. `}</p>
    <p>{`Each child of Flash must be a Card component, `}<strong parentName="p">{`all other components will be ignored and not rendered.`}</strong></p>
    <p>{`Each Card component recognizes it's children, following the child rule, where the first child is the question, the second child is the answer, and any other children are treated
as sub answer copy text. If you want a more complex question, or answer, it is recommended you use a Box component, and then render out your structured question to meet your needs. `}</p>
    <p>{`Once all the Flash cards have been revealed and answered, the session is marked as complete, and you'll see the "Review completed" dialogue. `}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`NOTE:`}</strong>{` Flashcard session state is currently tied to the Flash component itself, there is no global state storing answers at the time of this writing. `}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`NOTE:`}</strong>{` The `}<inlineCode parentName="p">{`Card`}</inlineCode>{` component here is not the theme-ui Card component, but a custom one within our own modules. `}</p>
    </blockquote>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`
<Flash>

<Card>

What kinds of conversations do we build together in Kernel?

Horizontal

</Card>

<Card>

Who's the coolest?

You're the coolest

</Card>

<Card>

For whom does the Taco Bell toll? 

Us All

</Card>

</Flash>
`}</code></pre>
    <Flash mdxType="Flash">
      <Card mdxType="Card">
        <p>{`What kinds of conversations do we build together in Kernel?`}</p>
        <p>{`Horizontal`}</p>
      </Card>
      <Card mdxType="Card">
        <p>{`Who's the coolest?`}</p>
        <p>{`You're the coolest`}</p>
      </Card>
      <Card mdxType="Card">
        <p>{`For whom does the Taco Bell toll? `}</p>
        <p>{`Us All`}</p>
      </Card>
    </Flash>
    <hr></hr>
    <h2 {...{
      "id": "info-block",
      "style": {
        "position": "relative"
      }
    }}>{`Info Block`}<a parentName="h2" {...{
        "href": "#info-block",
        "aria-label": "info block permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`This component is similar to that of a Hero image content section.
Depending on when you put the image is the side it will show up on. If there's no image first or last in content, the content will be centered.`}</p>
    <p><strong parentName="p">{`It is highly recommend you use a box component here. Each child is recognized and will render as so.`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`
<InfoBlock>

![starfox](/images/starfox.png)

<Box>

### Hello World

Some more content

</Box>

</InfoBlock>

<InfoBlock>

<Box>

### Hello World

Some more content

</Box>

<Image src="/images/starfox.png" />

</InfoBlock>

<InfoBlock>

<Box>

### I am centered

<Image src="/images/starfox.png" />

Some more content

</Box>

</InfoBlock>
`}</code></pre>
    <hr></hr>
    <InfoBlock mdxType="InfoBlock">
      <p><img parentName="p" {...{
          "src": "/images/starfox.png",
          "alt": "starfox"
        }}></img></p>
      <Box mdxType="Box">
        <h3 {...{
          "id": "hello-world",
          "style": {
            "position": "relative"
          }
        }}>{`Hello World`}<a parentName="h3" {...{
            "href": "#hello-world",
            "aria-label": "hello world permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h3>
        <p>{`Some more content`}</p>
      </Box>
    </InfoBlock>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <h3 {...{
          "id": "hello-world-1",
          "style": {
            "position": "relative"
          }
        }}>{`Hello World`}<a parentName="h3" {...{
            "href": "#hello-world-1",
            "aria-label": "hello world 1 permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h3>
        <p>{`Some more content`}</p>
      </Box>
      <Image src='/images/starfox.png' mdxType="Image" />
    </InfoBlock>
    <InfoBlock mdxType="InfoBlock">
      <Box mdxType="Box">
        <h3 {...{
          "id": "i-am-centered",
          "style": {
            "position": "relative"
          }
        }}>{`I am centered`}<a parentName="h3" {...{
            "href": "#i-am-centered",
            "aria-label": "i am centered permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h3>
        <Image src='/images/starfox.png' mdxType="Image" />
        <p>{`Some more content`}</p>
      </Box>
    </InfoBlock>
    <hr></hr>
    <h2 {...{
      "id": "list",
      "style": {
        "position": "relative"
      }
    }}>{`List`}<a parentName="h2" {...{
        "href": "#list",
        "aria-label": "list permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`To get the most out of lists (i.e. headers, subcontent, links) you MUST use a `}<inlineCode parentName="p">{`Box`}</inlineCode>{` or `}<inlineCode parentName="p">{`Link`}</inlineCode>{` component based on your specific needs.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`
<List>

Element A

Element B

</List>

<List>

<Box>

Element A Header

Some content below the header

</Box>

Element B with no subcontent

<Box>

Element C Header

Some content below the header

</Box>

</List>

<List>

<Link to="/">

Link Element A Header

Some content below the header

</Link>

<Link to="https://makerdao.com/">

External Link Element B Header

Some content below the header

</Link>

</List>
`}</code></pre>
    <hr></hr>
    <List mdxType="List">
      <p>{`Element A`}</p>
      <p>{`Element B`}</p>
    </List>
    <hr></hr>
    <List mdxType="List">
      <Box mdxType="Box">
        <p>{`Element A Header`}</p>
        <p>{`Some content below the header`}</p>
      </Box>
      <p>{`Element B with no subcontent`}</p>
      <Box mdxType="Box">
        <p>{`Element C Header`}</p>
        <p>{`Some content below the header`}</p>
      </Box>
    </List>
    <hr></hr>
    <List mdxType="List">
      <Link to="/" mdxType="Link">
        <p>{`Link Element A Header`}</p>
        <p>{`Some content below the header`}</p>
      </Link>
      <Link to="https://makerdao.com/" mdxType="Link">
        <p>{`External Link Element B Header`}</p>
        <p>{`Some content below the header`}</p>
      </Link>
    </List>
    <hr></hr>
    <h2 {...{
      "id": "process",
      "style": {
        "position": "relative"
      }
    }}>{`Process`}<a parentName="h2" {...{
        "href": "#process",
        "aria-label": "process permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`This components great for stepping folks through a numbered process.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`
<Process>
    ### Process Information 1 ### Process Information 2
    <Box>
        ### Process Information 3 Some more content here. The usual suspects and
        whatever you'd want to put.
    </Box>
</Process>
`}</code></pre>
    <hr></hr>
    <Process mdxType="Process">
      <h3 {...{
        "id": "process-information-1",
        "style": {
          "position": "relative"
        }
      }}>{`Process Information 1`}<a parentName="h3" {...{
          "href": "#process-information-1",
          "aria-label": "process information 1 permalink",
          "className": "anchor-link after"
        }}><span parentName="a">{`¶`}</span></a></h3>
      <h3 {...{
        "id": "process-information-2",
        "style": {
          "position": "relative"
        }
      }}>{`Process Information 2`}<a parentName="h3" {...{
          "href": "#process-information-2",
          "aria-label": "process information 2 permalink",
          "className": "anchor-link after"
        }}><span parentName="a">{`¶`}</span></a></h3>
      <Box mdxType="Box">
        <h3 {...{
          "id": "process-information-3",
          "style": {
            "position": "relative"
          }
        }}>{`Process Information 3`}<a parentName="h3" {...{
            "href": "#process-information-3",
            "aria-label": "process information 3 permalink",
            "className": "anchor-link after"
          }}><span parentName="a">{`¶`}</span></a></h3>
        <p>{`Some more content here. The usual suspects and whatever you'd want to put.`}</p>
      </Box>
    </Process>
    <hr></hr>
    <h3 {...{
      "id": "status-banner",
      "style": {
        "position": "relative"
      }
    }}>{`Status Banner`}<a parentName="h3" {...{
        "href": "#status-banner",
        "aria-label": "status banner permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <p>{`This is your run of the mill status banner. It's also capable of being sticky.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`
<StatusBanner>

Regular old status banner

</StatusBanner>

<StatusBanner warning>

Warning status!

</StatusBanner>

<StatusBanner sticky>

I will stick to the top of the screen.

</StatusBanner>
`}</code></pre>
    <hr></hr>
    <StatusBanner mdxType="StatusBanner">
      <p>{`Regular old status banner`}</p>
    </StatusBanner>
    <hr></hr>
    <StatusBanner warning mdxType="StatusBanner">
      <p>{`Warning status!`}</p>
    </StatusBanner>
    <hr></hr>
    <StatusBanner sticky mdxType="StatusBanner">
      <p>{`I will stick to the top of the screen.`}</p>
    </StatusBanner>
    <hr></hr>
    <h2 {...{
      "id": "tables",
      "style": {
        "position": "relative"
      }
    }}>{`Tables`}<a parentName="h2" {...{
        "href": "#tables",
        "aria-label": "tables permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`We support the lowest spec for tables in MD. None of that Github alignment stuff here.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`| Header A       | Header B                    |
| --------------- | ------------------------- |
| Content A        | Content A              |
| Content B          | Content B              |
`}</code></pre>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Header A`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Header B`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Content A`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content A`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Content B`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Content B`}</td>
        </tr>
      </tbody>
    </table>
    <hr></hr>
    <h2 {...{
      "id": "tout",
      "style": {
        "position": "relative"
      }
    }}>{`Tout`}<a parentName="h2" {...{
        "href": "#tout",
        "aria-label": "tout permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`Tout components are meant to be like ultra fancy links, but you can put anything inside of them.
`}<strong parentName="p">{`If there's only one child it will be full width.`}</strong></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`
<Tout>

Element A - Full Width

</Tout>


<Tout>

### Element A

### Element B

</Tout>
`}</code></pre>
    <hr></hr>
    <Tout mdxType="Tout">
      <p>{`Element A - Full Width`}</p>
    </Tout>
    <hr></hr>
    <Tout mdxType="Tout">
      <h3 {...{
        "id": "element-a",
        "style": {
          "position": "relative"
        }
      }}>{`Element A`}<a parentName="h3" {...{
          "href": "#element-a",
          "aria-label": "element a permalink",
          "className": "anchor-link after"
        }}><span parentName="a">{`¶`}</span></a></h3>
      <h3 {...{
        "id": "element-b",
        "style": {
          "position": "relative"
        }
      }}>{`Element B`}<a parentName="h3" {...{
          "href": "#element-b",
          "aria-label": "element b permalink",
          "className": "anchor-link after"
        }}><span parentName="a">{`¶`}</span></a></h3>
    </Tout>
    <hr></hr>
    <h2 {...{
      "id": "video",
      "style": {
        "position": "relative"
      }
    }}>{`Video`}<a parentName="h2" {...{
        "href": "#video",
        "aria-label": "video permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`It's possible to show videos here. They'll always be full width with no styles.`}</p>
    <hr></hr>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`
\`youtube: [Your love!](mzDVaKRApcg)\`

<Video src="https://www.youtube-nocookie.com/embed/kpk2tdsPh0A"/>
`}</code></pre>
    <hr></hr>
    <p><div parentName="p" {...{
        "className": "gatsby-resp-iframe-wrapper",
        "style": {
          "paddingBottom": "50%",
          "position": "relative",
          "height": "0",
          "overflow": "hidden"
        }
      }}>{` `}<div parentName="div" {...{
          "className": "embedVideo-container"
        }}>{` `}<iframe parentName="div" {...{
            "title": "Your love!",
            "src": "https://www.youtube.com/embed/mzDVaKRApcg?rel=0",
            "className": "embedVideo-iframe",
            "style": {
              "border": "0",
              "position": "absolute",
              "top": "0",
              "left": "0",
              "width": "100%",
              "height": "100%"
            },
            "loading": "eager",
            "allowFullScreen": true,
            "sandbox": "allow-same-origin allow-scripts allow-popups"
          }}></iframe>{` `}</div>{` `}</div></p>
    <hr></hr>
    <Video src='https://www.youtube-nocookie.com/embed/kpk2tdsPh0A' mdxType="Video" />
    <hr></hr>
    <h3 {...{
      "id": "text",
      "style": {
        "position": "relative"
      }
    }}>{`Text`}<a parentName="h3" {...{
        "href": "#text",
        "aria-label": "text permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <h1 {...{
      "id": "header-1",
      "style": {
        "position": "relative"
      }
    }}>{`Header 1`}<a parentName="h1" {...{
        "href": "#header-1",
        "aria-label": "header 1 permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <h2 {...{
      "id": "header-2",
      "style": {
        "position": "relative"
      }
    }}>{`Header 2`}<a parentName="h2" {...{
        "href": "#header-2",
        "aria-label": "header 2 permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <h3 {...{
      "id": "header-3",
      "style": {
        "position": "relative"
      }
    }}>{`Header 3`}<a parentName="h3" {...{
        "href": "#header-3",
        "aria-label": "header 3 permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h3>
    <h4 {...{
      "id": "header-4",
      "style": {
        "position": "relative"
      }
    }}>{`Header 4`}<a parentName="h4" {...{
        "href": "#header-4",
        "aria-label": "header 4 permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h4>
    <p>{`Large Text`}</p>
    <p><strong parentName="p">{`Bold text`}</strong></p>
    <p>{`Regular text`}</p>
    <Text variant='smallText' mdxType="Text"> Small text </Text>
    <Text variant='microText' mdxType="Text"> Micro text </Text>
    <Text variant='caps' mdxType="Text">Caps</Text>
    <Link to='/' mdxType="Link">Link </Link>
    <hr></hr>
    <p><strong parentName="p">{`This is a living document. Add or remove components as you change things. And always remember to have fun.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      